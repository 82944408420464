/* eslint-disable */
import MainTitle from '../../../components/MainTitle'

import { useState, useEffect } from 'react'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../../components/Pagination'
import {
  faPencil,
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../../components/Modal'
import { Link } from 'react-router-dom'
import CardButton from '../../../components/CardButton'

function Permissions() {
  const axios = useAxiosPrivate()

  const { setShowLoader, can, createInfo } = useAuth()

  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState({})
  const [createUser, setCreateUser] = useState({})
  const [deleteUser, setDeleteUser] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [userTypes, setUserTypes] = useState([])

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getUsers(), getUserTypes()])
    setShowLoader(false)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      let records = users.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(users)
      setCurrentRecords(users.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(users.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(0, recordsPerPage));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const getUsers = async () => {
    try {
      const response = await axios.get('/users/all')
      setUsers(response.data.result)
      setFilteredRecords(response.data.result)
      await setOriginalRecords(response.data.result);
      setCurrentRecords(
        response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(response.data.result.length / recordsPerPage))
    } catch (error) {}
  }

  const editUserClicked = (user) => {
    setEditUser({ ...user })
    setModalTitle(`Edit User: ${user.name}`)
    setShowModal(true)
  }

  const deleteUserClicked = (user) => {
    setDeleteUser({ ...user })
    setModalTitle(`Delete User: ${user.name}`)
    setShowModal(true)
  }

  const createUserClicked = (user) => {
    setCreateUser({})
    setModalTitle(`Create User`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (editUser.id) {
      return <EditUserElement />
    }

    if (deleteUser.id) {
      return <DeleteUserElement />
    }

    if (createUser) {
      return <CreateUserElement />
    }
  }

  const EditUserElement = () => {
    const updateUser = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const data = {
          name: editUser.name,
          email: editUser.email,
          type_id: editUser.type_id,
          phone_number: editUser.phone_number,
          date_of_birth: editUser.date_of_birth,
        }
        const response = await axios.post(
          '/users/update/' + editUser.id + '?filter=all',
          data
        )
        createInfo('info', `Updated User: ${editUser.name}`)
        setUsers(response.data.result)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateUser}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.name}
              type="text"
              name="name"
              id="name"
              onChange={(e) => (editUser.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.email}
              type="email"
              name="email"
              id="email"
              onChange={(e) => (editUser.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-600 text-sm"
          >
            Phone Number{' '}
            <span className="text-blue-500 text-xs">
              (Please use +44 and no spaces)
            </span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.phone_number}
              type="text"
              required
              name="phone_number"
              id="phone_number"
              onChange={(e) => (editUser.phone_number = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="phone_number"
            className="block font-medium text-gray-600 text-sm"
          >
            Date of Birth
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={editUser.date_of_birth}
              type="date"
              required
              name="date_of_birth"
              id="date_of_birth"
              onChange={(e) => (editUser.date_of_birth = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="mt-1 relative rounded-md">
            <label
              htmlFor="userType"
              className="block font-medium text-gray-600 text-sm"
            >
              User Type
            </label>
            <select
              defaultValue={editUser.type_id}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (editUser.type_id = Number(e.target.value))}
            >
              <option disabled selected>
                Select User Type
              </option>
              {userTypes?.map((type) => {
                if (can(`create ${type.title}`)) {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  )
                }
              })}
            </select>
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update User</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreateUserElement = () => {
    const addUser = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post('/users/create', createUser)
        if (response.status === 200) {
          createInfo('success', `Created User: ${createUser.name}`)
          setUsers([...users, response.data.result])
          setCurrentRecords([...currentRecords, response.data.result])
          resetModal(false)
        } else {
          console.error(response.data?.message)
          createInfo('error', 'Failed to create user')
          resetModal(false)
        }
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addUser}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.name}
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (createUser.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block font-medium text-gray-600 text-sm"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createUser.email}
              type="email"
              required
              name="email"
              id="email"
              onChange={(e) => (createUser.email = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="mt-1 relative rounded-md">
            <label
              htmlFor="userType"
              className="block font-medium text-gray-600 text-sm"
            >
              User Type
            </label>
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createUser.type_id = Number(e.target.value))}
            >
              <option disabled selected>
                Select User Type
              </option>
              {userTypes?.map((type) => {
                if (can(`create ${type.title}`)) {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  )
                }
              })}
            </select>
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create User</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const getUserTypes = async (e) => {
    const response = await axios.get('/user-types/all')
    setUserTypes(response.data.result)
  }

  const DeleteUserElement = () => {
    const deleteUserFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.get(
          '/users/delete/' + deleteUser.id + '?filter=all'
        )
        createInfo('error', `Deleted User: ${deleteUser.name}`)
        setUsers(response.data.result)
        setFilteredRecords(response.data.result)
        setCurrentRecords(
          response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
        )
        setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteUserFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this user?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete User</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetUsers = true) => {
    setEditUser({})
    setDeleteUser({})
    setCreateUser({})
    setShowModal(false)
    if (resetUsers) {
      setUsers(users)
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <ul className="flex justify-end flex-wrap">
        <li className="cursor-pointer">
          <div onClick={createUserClicked} className="px-4 py-2 bg-dark-purple border border-dark-purple rounded-lg shadow flex items-center cursor-pointer hover:bg-nav-dark">
            <FontAwesomeIcon icon={faPlus} className="text-xl text-white mr-4" />
            <h5 className="text-base font-semibold tracking-tight text-white">
              Create User
            </h5>
          </div>
        </li>
      </ul>

      <div className="py-4">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Users"
          />
        </div>
      </div>

      <table className="table-main">
        <thead>
          <tr>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('email')}>Email {renderSortArrow('email')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('type')}>User Type {renderSortArrow('type')}</th>
            <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')}</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.length > 0 ? (
            currentRecords.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.type}</td>
                  <td>{user.status}</td>
                  <td>
                    <div className="flex justify-center">
                      <Link to={`/users/${user.id}`}>
                        <span className="flex justify-center items-center bg-teal-400 rounded-md text-teal-800 h-9 w-12 hover:bg-teal-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </Link>

                      <span
                        onClick={() => editUserClicked(user)}
                        className="flex justify-center items-center bg-orange-300 rounded-md text-orange-800 h-9 w-12 mx-2  hover:bg-orange-400 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </span>
                      <span
                        onClick={() => deleteUserClicked(user)}
                        className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 hover:bg-red-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <p className="no-records">No users </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default Permissions
