import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, Fragment } from "react";
import { useNavigate } from 'react-router-dom'
import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'

function PPQTitle({ submissionDate, claimReference, orgDefined, backButton = false, task }) {
  const axios = useAxiosPrivate();
  const navigate = useNavigate()
  const { createInfo } = useAuth()
  const [reference, setReference] = useState(claimReference)
  const [isAssignToOpen, setIsAssignToOpen] = useState(false)
  
  const [isAssigning, setIsAssigning] = useState(false)
  const [assigningTo, setAssigningTo] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([{}])

  const assignTask = async (taskId, userId) => {
    console.log(taskId, userId)
    if (isAssigning) return
    setIsAssigning(true)
    setAssigningTo(taskId)
    try {
      const response = await axios.post(`/tasks/assign-task/${taskId}/${userId}`, {});
      if (response.data.error === false) {
        setIsAssignToOpen(false)
        createInfo('success', `Task Assigned`);
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      console.error(error.message);
      createInfo('error', `Failed to assign task`);
    } finally {
      setIsAssigning(false)
      setAssigningTo(null)
    }
  }

  const getUsers = async () => {
    try {
      const response = await axios.get('/users/all')
      let unassignedUser = {
        id: '0',
        name: 'Unassigned',
        first_name: 'Unassigned',
        email: '',
        profile_colour: 'gray',
        profile_pic: 'default.png'
      };
      let usersWithUnassigned = [unassignedUser, ...response.data.result];
      setAllUsers(usersWithUnassigned)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const filterUsers = (handle) => {
    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = allUsers.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString)
        )
      })
      setFilteredUsers(records)
    } else {
      setFilteredUsers(allUsers)
    }
  }

  const goBack = () => {
    window.location.href = '/'
  }

  useEffect(() => {
    setReference(claimReference)
  }, [claimReference])

  useEffect(() => {
    setFilteredUsers(allUsers)
  }, [allUsers])

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div className="pb-4 text-gray-700">
      <div className='flex justify-between items-center pb-4'>
        {backButton && (
          <span
            className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <span className="text-sm ml-2 font-bold">Go Back</span>
          </span>
        )}
        <div>
          {/* <a href='https://schooladvice.co.uk/' target='blank' className='bg-nav hover:bg-nav-dark rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'> <FontAwesomeIcon icon={faBell} className="" /></a> */}
        </div>
      </div>

      <div className='bg-white rounded-lg p-4 flex justify-between text-gray-700'>
        <div >
          <div className='flex items-start justify-between'>
            <div>
              <h2 className='font-semibold text-3xl flex items-center'> 
                PPQ Request - {reference} 
              </h2>
              {submissionDate && (
                <div className='flex items-center space-x-4 text-sm text-gray-500'>
                  <div>Submission Date: {submissionDate}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex space-x-4">
            {!orgDefined && (
              <>
                <div className='flex items-center space-x-4 text-sm text-red-800'>
                <div>Organisation Not Imported</div>
                </div>
              </>
            )}
            
            <Menu as="div" className="relative inline-block text-left" open={isAssignToOpen} onClose={() => setIsAssignToOpen(false)}>
              <MenuButton className={`hover:scale-110 transition duration-300 ease-in-out rounded-full mt-2 mr-2 z-10 group ${task?.assigned_to ? 'bg-' + task?.assigned_to?.profile_colour + '-500' : 'bg-gray-400'}`} onClick={(e) => e.stopPropagation()}>

                <div className="w-14 h-14 overflow-hidden rounded-full relative">
                  {assigningTo && assigningTo === task.id ? (
                    <div className="h-14 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out">
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin text-2xl" />
                    </div>
                  ) : (
                    <>
                      {task?.assigned_to ? (
                        <div className="w-14 h-14 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out">
                          {task.assigned_to.profile_pic.includes('default.png')? (
                            <p className={`bg-[${task.assigned_to.profile_colour}] text-white`}>
                              {task.assigned_to.name.split(' ').map(n => n[0].toUpperCase()).join('')}
                            </p>
                          ) : (
                            <img src={task.assigned_to.profile_pic} alt="User Profile" className="w-12 h-12 rounded-full" />
                          )}
                          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full bg-gray-700 text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100">
                            {task.assigned_to.name}
                          </span>
                        </div>
                      ) : (
                        <div className="w-14 h-14 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out pointer-events-none">
                          <FontAwesomeIcon icon={faUser} className="text-2xl" />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {task?.assigned_to && (
                  <span className="absolute bottom-5 left-5 transform translate-x-[-100%] translate-y-full bg-gray-700 text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100">
                    {task.assigned_to.name}
                  </span>
                )}
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems
                  className="absolute right-0 mt-2 w-[250px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-y-scroll max-h-[400px]"
                  onBlur={() => setTimeout(() => setFilteredUsers(allUsers), 200)}
                >
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="Search..."
                      onChange={filterUsers}
                    />
                  </div>
                  <div className="p-2">
                    {filteredUsers?.map((user) => (
                      <div key={user.id} onClick={(e) => {e.stopPropagation(); assignTask(task.id, user.id)}} className="hover:bg-gray-200 rounded-xl px-2 py-1">
                        <div className="flex items-center space-x-4">
                          <div className={`w-10 h-10 flex items-center justify-center cursor-pointer transition duration-150 ease-in-out rounded-full bg-${user.profile_colour}-500`}>
                            {user?.profile_pic?.includes('default.png')? (
                              <p className="text-white">
                                {user.name.split(' ').map(n => n[0].toUpperCase()).join('')}
                              </p>
                            ) : (
                              <img
                                src={user.profile_pic}
                                alt="User Profile"
                                className="w-10 h-10 rounded-full transition-transform duration-150 ease-in-out transform hover:scale-110"
                              />
                            )}
                          </div>
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {user.first_name} {user.last_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PPQTitle
