import MainTitle from '../../components/MainTitle';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import { useState, useEffect, useMemo } from 'react';
import { faCircleCheck, faRectangleList, faTimes, faPlusCircle, faArrowUp, faArrowDown, faListCheck, faPen, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TaskView from './Partials/Task';
import Todo from '../../components/TodoSlideOut';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import useAuth from '../../hooks/useAuth';

ChartJS.register(ArcElement, Tooltip, Legend);

function Tasks() {
  const axios = useAxiosPrivate();
  const { createInfo, auth } = useAuth()
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([])
  const [display, setDisplay] = useState('All')
  const [addTask, setAddTask] = useState(false)
  const [open, setOpen] = useState(false)
  const [productivityHours, setProductivityHours] = useState(0)
  const [stats, setStats] = useState([])
  const [users, setUsers] = useState([])
  const [tasksLoading, setTasksLoading] = useState(false)
  const [taskListFilter, setTaskListFilter] = useState('')

  const TaskPieChart = ({ tasks }) => {
    const completedTasks = tasks.filter((task) => task.completed).length
    const pendingTasks = tasks.filter((task) => !task.completed).length

    const data = useMemo(() => ({
      labels: ['Completed Tasks', 'Pending Tasks'],
      datasets: [
        {
          label: 'Task Completion',
          data: [completedTasks, pendingTasks],
          backgroundColor: ['#A581B1', '#14b8a6'],
          hoverBackgroundColor: ['#A581B1', '#14b8a6'],
        },
      ],
    }), [completedTasks, pendingTasks]);

    const options = {
      animation: {
        duration: 0,
      },
    };

    return (
      <div className="w-80 mx-auto mt-4">
        <Pie data={data} options={options} />
      </div>
    );
  };

  const refreshTasks = () => {
    getTaskList()
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const getTaskList = async () => {
    setTasksLoading(true)
    try {
      const response = await axios.get('/tasks/all')
      const tasks = response.data.result
      setTasks(tasks)
      if (taskListFilter.length > 0) {
        let records = tasks.filter((e) => {
          return (
            e.title.toLowerCase().match(taskListFilter) ||
            (e.assigned_to && e.assigned_to.name && e.assigned_to.name.toLowerCase().match(taskListFilter)) ||
            (e.task_info && e.task_info.request_information && e.task_info.request_information.claim_reference && e.task_info.request_information.claim_reference.toLowerCase().match(taskListFilter))
          )
        })
        setFilteredTasks(records)
      } else {
        setFilteredTasks(tasks)
      }
      calculateStats(tasks)
      setTasksLoading(false)
    } catch (error) {
      createInfo('error', `Failed to fetch tasks`)
      console.error('Error fetching tasks:', error)
      setTasksLoading(false)
    }
  }
    
  const addTaskClicked = () => {
    setOpen(true)
  }

  useEffect(() => {
    getUsers()
    getTaskList()
  }, [])

  const getUsers = async () => {
    try {
      const response = await axios.get('/users/all')
      let unassignedUser = {
        id: '0',
        name: 'Unassigned',
        first_name: 'Unassigned',
        email: '',
        profile_colour: 'gray',
        profile_pic: 'default.png'
      };
      let usersWithUnassigned = [unassignedUser, ...response.data.result];
      setUsers(usersWithUnassigned);
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const filterTaskList = (handle) => {
    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    setTaskListFilter(searchString)
    if (searchString.length > 0) {
      let records = tasks.filter((e) => {
        return (
          e.title.toLowerCase().match(searchString) ||
          (e.assigned_to && e.assigned_to.name && e.assigned_to.name.toLowerCase().match(taskListFilter)) ||
          (e.task_info && e.task_info.request_information && e.task_info.request_information.claim_reference && e.task_info.request_information.claim_reference.toLowerCase().match(searchString))
        )
      })
      setFilteredTasks(records)
    } else {
      setFilteredTasks(tasks)
    }
  }

  const calculateStats = (allTasks) => {
    const totalTasks = allTasks.length
    const completedTasks = allTasks.filter(task => task.completed).length
    const currentWeekStart = moment().startOf('isoWeek')
    const tasksThisWeek = allTasks.filter(task =>
      moment(task.created_at).isSameOrAfter(currentWeekStart)
    ).length

    setStats([
      { id: 1, name: 'Total Tasks', stat: totalTasks, icon: faRectangleList },
      { id: 2, name: 'Tasks Completed This Week', stat: tasksThisWeek, icon: faListCheck },
      { id: 3, name: 'Completed Tasks', stat: completedTasks, icon: faCircleCheck }
    ])
  }

  const determineStatus = (task) => {
    let today = moment();
    let dueDate = moment(task.due_date, 'YYYY-MM-DD');

    if (task.completed || task.status === 'Completed') {
      return 'Completed';
    } else if (dueDate.isBefore(today, 'day')) {
      return 'Overdue';
    } else if (task.status === 'In Progress') {
      return 'In Progress';
    } else {
      return 'Due';
    }
  };

  // Calculating task counts
  const allCount = tasks.length;
  const dueCount = filteredTasks.filter(task => determineStatus(task) === 'Due').length
  const overdueCount = filteredTasks.filter(task => determineStatus(task) === 'Overdue').length
  const inProgressCount = filteredTasks.filter(task => determineStatus(task) === 'In Progress').length
  const completedCount = filteredTasks.filter(task => determineStatus(task) === 'Completed').length
  const myTaskCount = filteredTasks.filter(task => task.assigned_to && task.assigned_to.id === auth.user.id).length

  return (
    <section className='text-gray-700'>
      <Todo open={open} setOpen={setOpen} />
      {/* <div className='mb-4'>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((item) => (
            <div key={item.id} className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-dark-purple px-3 py-2">
                  <FontAwesomeIcon icon={item.icon} className="h-6 w-6 text-white" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
              </dt>
              <div className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                {item.change && (
                  <p
                    className={`ml-2 flex items-baseline text-sm font-semibold ${item.changeType === 'increase' ? 'text-green-600' : 'text-red-600'}`}
                  >
                    {item.changeType === 'increase' ? (
                      <FontAwesomeIcon icon={faArrowUp} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-green-500" />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-red-500" />
                    )}
                    <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                    {item.change}
                  </p>
                )}
              </div>
            </div>
          ))}
        </dl>
      </div> */}

      <div className="flex items-start justify-between space-x-8">
        {/* <div className="w-1/3">
          <div className="bg-white rounded-lg p-4 mb-6">
            <div className="flex items-center space-x-2 border-b pb-2">
              <FontAwesomeIcon icon={faPen} />
              <div>To Do List</div>
            </div>
            <ul className="mt-4 space-y-2">
              <li className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-dark-purple" />
                <span>Finish project documentation</span>
              </li>
              <li className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-dark-purple" />
                <span>Schedule team meeting for next week</span>
              </li>
              <li className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-dark-purple" />
                <span>Review pull requests</span>
              </li>
              <li className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-dark-purple" />
                <span>Prepare presentation slides for Friday</span>
              </li>
              <li className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-dark-purple" />
                <span>Update client on progress</span>
              </li>
            </ul>
          </div>

          <div className="bg-white rounded-lg p-4 mb-6">
            <div className="flex items-center justify-between border-b pb-2">
              <div>Reminders</div>
              <div className="text-xs bg-light-purple shadow rounded-lg px-2 py-1 flex items-center space-x-2">
                <FontAwesomeIcon icon={faArrowsRotate} />
                <div>Sync Outlook</div>
              </div>
            </div>
            <ul className="mt-4 space-y-2">
              <li className="flex justify-between">
                <span>Project kickoff meeting</span>
                <span className="text-gray-500 text-xs">Mon, 9:00 AM</span>
              </li>
              <li className="flex justify-between">
                <span>Submit timesheet</span>
                <span className="text-gray-500 text-xs">Tue, 5:00 PM</span>
              </li>
              <li className="flex justify-between">
                <span>Team review call</span>
                <span className="text-gray-500 text-xs">Wed, 11:00 AM</span>
              </li>
              <li className="flex justify-between">
                <span>Client feedback session</span>
                <span className="text-gray-500 text-xs">Thu, 3:00 PM</span>
              </li>
              <li className="flex justify-between">
                <span>Friday wrap-up</span>
                <span className="text-gray-500 text-xs">Fri, 4:30 PM</span>
              </li>
            </ul>
          </div>

        </div> */}

        {/* Task List */}
        <div className="w-full">
          <div className="col-span-5">
            <div className='flex justify-between space-x-4'>
              {/* Task Stats */}
              {/* <div className="my-4 w-3/4">
                <h3 className="text-xl font-medium">Task Progress</h3>
                <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                  <div className="bg-[#ab366a] h-4 rounded-full" style={{ width: `${(completedCount / allCount) * 100}%` }}></div>
                </div>
                <p>{completedCount} out of {allCount} tasks completed</p>
              </div> */}

              {/* Task Pie Chart */}
              {/* <div className="my-10">
                <h2 className="text-xl font-medium">Task Completion This Month</h2>
                <TaskPieChart tasks={tasks} />
              </div> */}
            </div>
            <div className="shadow py-6 px-4 rounded-xl bg-white">
              <div>
                <h2 className="text-xl font-medium">Task List</h2>
              </div>
              <div className="flex items-center relative my-6">
                <input
                  type="text"
                  id="tasks_search"
                  onChange={filterTaskList}
                  className="border border-gray-200 rounded-md h-9 w-full px-2 text-sm text-slate-800 focus:ring-violet-400 focus:border-violet-400"
                  placeholder="Search"
                />
                <span className="flex justify-center items-center bg-hub-primary-hover rounded-md text-white h-9 w-9 mx-2 px-2 hover:bg-hub-primary cursor-pointer">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
              <div className="flex justify-between border-b-2 border-dark-blue">
                <div className="flex items-center space-x-2 xl:text-base text-sm">
                  <div
                    onClick={() => setDisplay('All')}
                    className={`cursor-pointer px-2 ${display === 'All' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    All ({allCount})
                  </div>
                  <div
                    onClick={() => setDisplay('My Tasks')}
                    className={`cursor-pointer px-2 ${display === 'My Tasks' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    My Tasks ({myTaskCount})
                  </div>
                  <div
                    onClick={() => setDisplay('Due')}
                    className={`cursor-pointer px-2 ${display === 'Due' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    Due ({dueCount})
                  </div>
                  <div
                    onClick={() => setDisplay('In Progress')}
                    className={`cursor-pointer px-2 ${display === 'In Progress' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    In Progress ({inProgressCount})
                  </div>
                  <div
                    onClick={() => setDisplay('Overdue')}
                    className={`cursor-pointer px-2 ${display === 'Overdue' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    Overdue ({overdueCount})
                  </div>
                  {/* <div
                    onClick={() => setDisplay('Important')}
                    className={`cursor-pointer px-2 ${display === 'Important' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    Important ({importantCount})
                  </div> */}
                  <div
                    onClick={() => setDisplay('Done')}
                    className={`cursor-pointer px-2 ${display === 'Done' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                    Done ({completedCount})
                  </div>
                </div>
                <div onClick={addTaskClicked} title='Add Task'>
                  <FontAwesomeIcon icon={faPlusCircle} className="text-hub-primary-hover cursor-pointer h-5 w-5 hover:text-hub-primary" />
                </div>
              </div>
              <div className="overflow-y-scroll h-fit max-h-[80vh] min-h-[350px]">
                <TaskView tasks={filteredTasks} filter={display} refreshTasks={refreshTasks} loading={tasksLoading} allUsers={users} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tasks;
