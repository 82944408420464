import { faBuilding, faChevronCircleRight, faLink, faHouse, faLocationDot, faChevronCircleDown, faCircleCheck, faRefresh, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useParams } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import ContactSlideout from '../pages/Admin/Organisations/ContactSlideout'
function OrgTitle({ org, status = null, orgPolicy = false, links = false, icon = false, toolTipData= null}) {
  const navigate = useNavigate()
  const { setShowLoader, createInfo } = useAuth()
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)

  const toggleMoreDetails = () => {
    setModalOpen(true)
    setShowMoreDetails(!showMoreDetails);
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const refreshOrganisation = async () => {
    try {
      setShowLoader(true);
      const url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/organisation/refresh?api_token=${process.env.REACT_APP_API_KEY}`;

      const response = await axios.post(url, org)
      const refreshedData = response.data.data
      await axios.post(`/organisations/refresh/${id}`, refreshedData);
      createInfo('success', `Organisation Updated`);
    } catch (error) {
      createInfo('error', `Failed to update organisation: ${error.message}`);
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 5000)
      setShowLoader(false);
    }
  };

  let policies = [];

  if (org?.policy_information) {
    try {
      policies = JSON.parse(org.policy_information);
    } catch (error) {
      console.error('Invalid JSON in policy_information:', error);
    }
  }
  const livePolicy = policies.find(policy => policy.status === 'live' || policy.status === 'expiring' || policy.status === 'expired' || policy.status === 'accepted' || policy.status === 'previous');
  const policyInception = livePolicy ? livePolicy.inception : 'N/A';
  const policyExpire = livePolicy ? livePolicy.expire : 'N/A';

  const getStatusColorClass = (policyStatus) => {
    switch (policyStatus?.toLowerCase()) {
      case 'live':
        return 'text-green-500';
      case 'expiring':
        return 'text-orange-500';
      case 'expired':
        return 'text-red-500';
      default:
        return 'text-blue-500';
    }
  };

  return (
    <div className="pb-4 text-gray-700">

      <div className='bg-white rounded-lg p-4 flex justify-between text-gray-700'>
        <div className='w-[80%]'>
          <div className='flex items-start justify-between'>
            <div>
              <h2 className='font-semibold text-3xl flex items-center'> 
                {org.name} 
                <span className='text-xs font-base ml-4'>
                  {status && (
                    <span className={`${getStatusColorClass(orgPolicy?.status)} text-xs font-base ml-4`}>
                      <FontAwesomeIcon icon={faCircleCheck} className='mr-2' />
                      {status} - {orgPolicy?.product}
                    </span>
                  )}
                </span>
              </h2>
              <div className='flex items-center space-x-4 text-sm text-gray-500'>
                <div>Policy Start: {moment(policyInception, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                <div>|</div>
                <div>Policy End: {moment(policyExpire, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
              </div>
            </div>
          </div>
          
          <div className='flex items-start justify-between text-sm mt-8 w-full'>
            <div className='space-y-2'> 
              <div> <FontAwesomeIcon icon={faBuilding} className="text-sm" /> <span className='pl-2'>{org?.unique_reference}</span></div>
              <div> <FontAwesomeIcon icon={faHouse} className="text-sm"/> <span className='pl-2'>{org?.type}</span></div>
              <div> <FontAwesomeIcon icon={faLocationDot} className="text-sm"/> <span className='pl-2'>{org?.address}</span></div>
              {org?.trust_name && 
                (<div> <FontAwesomeIcon icon={faLink} className="text-sm"/> <span className='pl-2'>Belongs to {org?.trust_name}</span></div>)
              }
            </div>
            <div className='space-y-2'> 
              <div> <span className='font-semibold'>Staff Contacts</span></div>
              <div>
                <FontAwesomeIcon icon={faUser} className="text-sm text-orange-700" />
                <span className="pl-2 capitalize">
                  {org?.contacts?.length > 0 ? (
                    <>
                      <span className='text-orange-700'>Main Contact: </span>
                      {org?.contacts?.find((contact) => contact.main_contact === 1)?.contact_name || 'Not Specified'}
                    </>
                  ) : (
                    'No Contacts Available'
                  )}
                </span>
              </div>
              <div className='text-[#71ADFF] cursor-pointer' onClick={toggleMoreDetails}> <FontAwesomeIcon icon={showMoreDetails ? faChevronCircleDown : faChevronCircleRight} className="text-sm" /> <span className='pl-2 hover:underline'>More Contacts</span></div>
            </div>
          </div>
        </div>
          <div className='flex items-end justify-end text-xs space-x-2'>
            <div> 
                <button className="px-4 py-3 rounded-lg text-white font-medium shadow-sm hover:bg-teal-600 bg-teal-500" onClick={refreshOrganisation}>
                  Refresh <FontAwesomeIcon className='ml-2' icon={faRefresh}/> 
                </button>
            </div>
          </div>
      </div>
      <ContactSlideout isOpen={modalOpen} onClose={closeModal} contacts={org?.contacts} />
    </div>
  )
}

export default OrgTitle
